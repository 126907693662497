/* eslint-disable no-nested-ternary */
import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import Header from "../components/header";
import instagramIcon from "../images/instagram.svg";
import facebookIcon from "../images/facebook.svg";
import websiteIcon from "../images/website.svg";
import { Hero } from "../components";
import * as ROUTES from "../constants/routes";
import Footer from "../components/footer";
import { IUser } from "../App";
import { getCondidates } from "../modules/Voting/voting.crud";

interface IProps {
    setGlobalUser: React.Dispatch<React.SetStateAction<IUser>>;
}
const Home: React.FC<IProps> = ({ setGlobalUser }) => {
    const history = useHistory();

    useEffect(() => {
        setGlobalUser({
            user: {
                username: "",
                password: "",
            },
            token: "",
            isAuthorized: false,
            didVote: undefined,
        });
    }, []);
    return (
        <>
            <Header>
                <Header.Logo />
                <Header.Group>
                    <Header.Pane>
                        <Header.MediaHero
                            link="https://www.instagram.com/cse.club/"
                            src={instagramIcon}
                        />
                        <Header.MediaHero
                            link="https://www.facebook.com/club.scientifique.esi/"
                            src={facebookIcon}
                        />
                        <Header.MediaHero link="https://cse.club/" src={websiteIcon} />
                    </Header.Pane>
                </Header.Group>
            </Header>
            <Header.Title>
                CSE Presidential
                <br />
                2022 - 2023
            </Header.Title>
            <Hero>
                <Hero.Video />
                <Hero.Desc>
                    A new season is getting ready while another comes to its end. Welcome to the{" "}
                    <strong>CSE</strong> presidential election for the 2022-2023 season. Be
                    responsible and make the right choice for the future of the club.
                </Hero.Desc>
                <Hero.Button onClick={() => history.push(ROUTES.SIGNIN)}>
                    Start the vote
                </Hero.Button>
            </Hero>

            <Footer>
                <Footer.CopyRights>
                    Copyright © 2022 Club Scientifique de l'ESI. All Rights Reserved. | Privacy
                    Policy
                </Footer.CopyRights>
            </Footer>
        </>
    );
};

export default Home;
