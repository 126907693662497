/* eslint-disable react/self-closing-comp */
/* eslint-disable no-nested-ternary */
import React, { useState } from "react";
import { Helmet } from "react-helmet";
import { Redirect } from "react-router-dom";
import { Hero, PopUp } from "../components";
import Header from "../components/header";
import { auth, provider } from "../lib/firebase.prod";
import instagramIcon from "../images/instagram.svg";
import facebookIcon from "../images/facebook.svg";
import websiteIcon from "../images/website.svg";
import Footer from "../components/footer";
import { login } from "../modules/Users/users.crud";
import { IUser } from "../App";
import { CONDIDATES } from "../constants/routes";
import "./Signin.css";

interface IProps {
    globalUser: IUser;
    setGlobalUser: React.Dispatch<React.SetStateAction<IUser>>;
}

const SignIn: React.FC<IProps> = ({ globalUser, setGlobalUser }) => {
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");

    const [loadingCTA, setLoadingCTA] = useState(false);

    // const [eligible, setEligible] = useState(false);
    const user = auth.currentUser;

    const handleChangeName = (e: React.ChangeEvent<HTMLInputElement>) => {
        setUsername(e.target.value);
    };
    const handleChangePassword = (e: React.ChangeEvent<HTMLInputElement>) => {
        setPassword(e.target.value);
    };

    const handleSubmit = () => {
        setLoadingCTA(true);
        const dataForm = new FormData();

        dataForm.append("username", username);
        dataForm.append("password", password);
        login(dataForm)
            .then(({ data }) => {
                setGlobalUser({
                    user: { username, password },
                    isAuthorized: data.access && username !== "",
                    didVote: false,
                    token: data.access,
                });
            })
            .catch((e) => console.log(e));

        const timer = setTimeout(() => {
            setLoadingCTA(false);
        }, 2500);
        return () => clearTimeout(timer);
    };

    return (
        <>
            <Header>
                <Header.Logo />
                <Header.Group>
                    <Header.Pane>
                        <Header.MediaHero
                            link="https://www.instagram.com/cse.club/"
                            src={instagramIcon}
                        />
                        <Header.MediaHero
                            link="https://www.facebook.com/club.scientifique.esi/"
                            src={facebookIcon}
                        />
                        <Header.MediaHero link="https://cse.club/" src={websiteIcon} />
                    </Header.Pane>
                </Header.Group>
            </Header>
            <Header.Title>
                CSE Presidential
                <br />
                2022 - 2023
            </Header.Title>
            <Hero>
                <Helmet>
                    <script src="/jquery-2.2.2.min.js"></script>
                </Helmet>
                <PopUp>
                    <div className="arrow bounce"></div>
                    <div id="envelope" className="pulse">
                        <div id="envelope-up" />
                        <div id="card">
                            <div id="close" />
                            <h1>Authentication</h1>
                            <input
                                type="text"
                                id="name"
                                name="user_name"
                                placeholder="Enter your e-mail address"
                                value={username}
                                onChange={handleChangeName}
                            />
                            <input
                                type="password"
                                id="email"
                                name="email"
                                placeholder="Enter your password 👀"
                                value={password}
                                onChange={handleChangePassword}
                            />

                            <PopUp.Button cta={handleSubmit}>SignIn</PopUp.Button>
                        </div>
                    </div>

                    <PopUp.Content>
                        {loadingCTA ? <PopUp.Loader /> : <Redirect to={{ pathname: CONDIDATES }} />}
                    </PopUp.Content>
                </PopUp>
            </Hero>

            <Footer>
                <Footer.CopyRights>
                    Copyright © 2022 Club Scientifique de l'ESI. All Rights Reserved. | Privacy
                    Policy
                </Footer.CopyRights>
            </Footer>
        </>
    );
};

export default SignIn;
