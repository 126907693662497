/* eslint-disable no-nested-ternary */
import React from "react";
import { useHistory } from "react-router-dom";
import Header from "../components/header";
import instagramIcon from "../images/instagram.svg";
import facebookIcon from "../images/facebook.svg";
import websiteIcon from "../images/website.svg";
import { Hero } from "../components";
import * as ROUTES from "../constants/routes";
import Footer from "../components/footer";

const Page404: React.FC = () => {
    const history = useHistory();

    return (
        <>
            <Header>
                <Header.Logo />
                <Header.Group>
                    <Header.Pane>
                        <Header.MediaHero
                            link="https://www.instagram.com/cse.club/"
                            src={instagramIcon}
                        />
                        <Header.MediaHero
                            link="https://www.facebook.com/club.scientifique.esi/"
                            src={facebookIcon}
                        />
                        <Header.MediaHero link="https://cse.club/" src={websiteIcon} />
                    </Header.Pane>
                </Header.Group>
            </Header>
            <Header.Title>404 Page Error !</Header.Title>
            <Hero>
                <Hero.Video />
                <Hero.Button onClick={() => history.push(ROUTES.HOME)}>GO TO HOME</Hero.Button>
            </Hero>

            <Footer>
                <Footer.CopyRights>
                    Copyright © 2022 Club Scientifique de l'ESI. All Rights Reserved. | Privacy
                    Policy
                </Footer.CopyRights>
            </Footer>
        </>
    );
};

export default Page404;
