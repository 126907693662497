import styled from "styled-components/macro";
import LogoCSE from "../../../images/CSELOgo.svg";
import LogoCSEDesktop from "../../../images/LogoWhite.svg";
import { media } from "../../../styles/global-style";

export const Container = styled.div`
    padding: 10px 10vw;
    height: 15vh;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: sticky;
    top: 0;
    left: 0;
    background-color: #4b5377;
`;

export const Logo = styled.div`
    height: 56px;
    width: 200px;
    background-repeat: no-repeat;
    background-size: contain;
    background-image: url(${LogoCSEDesktop});
    cursor: pointer;

    ${media.phone} {
        background-image: url(${LogoCSE});
        width: 100px;
    }
`;

export const Group = styled.div`
    display: flex;
    align-items: center;
`;

export const Title = styled.h2`
    color: white;
    font-size: 2rem;
    width: 85%;
    text-align: center;
    margin: 0 auto;
`;

export const Pane = styled.div``;

export const Media = styled.a`
    margin-right: 50px;

    ${media.phone} {
        display: none;
    }
`;

export const MediaHero = styled.a`
    margin-right: 50px;

    ${media.phone} {
        margin-right: 25px;
    }
    :last-child {
        margin-right: 0px;
    }
`;

export const Icon = styled.img``;

export const LogOut = styled.p`
    display: flex;
    align-items: center;
    color: white;
    cursor: pointer;
    position: relative;
    &::before {
        content: "";
        width: 0;

        height: 1px;
        position: absolute;
        bottom: 0;
        left: 0;
        background-color: white;
        transition: width 0.5s ease;
    }
    &:hover {
        &::before {
            width: 70%;
        }
    }
`;
