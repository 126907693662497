import React from "react";
import { ICardComposition } from "../../interfaces/ICard";
import {
  Bar,
  Container,
  Infos,
  Name,
  NameResult,
  NBVotes,
  Percent,
  Picture,
  ProgressBar,
  Role,
} from "./styles/Card";

const Card: React.FC & ICardComposition = ({ children, ...restProps }) => (
  <Container {...restProps}>{children}</Container>
);

Card.Infos = function CardInfos({ children, ...restProps }) {
  return <Infos {...restProps}>{children}</Infos>;
};

Card.Picture = function CardPicture({ children, ...restProps }) {
  return <Picture {...restProps}>{children}</Picture>;
};

Card.Name = function CardName({ children, ...restProps }) {
  return <Name {...restProps}>{children}</Name>;
};

Card.NameResult = function CardNameResult({ children, ...restProps }) {
  return <NameResult {...restProps}>{children}</NameResult>;
};

Card.Role = function CardRole({ children, ...restProps }) {
  return <Role {...restProps}>{children}</Role>;
};

Card.NBVotes = function CardNBVotes({ children, ...restProps }) {
  return <NBVotes {...restProps}>{children}</NBVotes>;
};

Card.ProgressBar = function CardProgressBar({
  max,
  value,
  children,
  ...restProps
}) {
  return (
    <ProgressBar {...restProps}>
      <Bar max={max} value={value} />
      <Percent>{children}</Percent>
    </ProgressBar>
  );
};

export default Card;
