import "firebase/auth";
import firebase from "firebase/app";
// import { firebaseConfig } from "../firebase-config";

// const app = !firebase.apps.length ? firebase.initializeApp(firebaseConfig) : firebase.app();

// const auth = app.auth();
// const provider = new firebase.auth.GoogleAuthProvider();

const auth = {} as any;
const provider = {} as any;

export { auth, provider };
