import React from "react";
import { Redirect, Route, RouteProps } from "react-router-dom";
import { IUser } from "../App";
import { CONDIDATES, RESULTS, SIGNIN } from "../constants/routes";

interface IRoutesHelper {
  user?: IUser;
  loggedInPath?: string;
}

export const ProtectedCondidatesRoute: React.FC<IRoutesHelper & RouteProps> = ({
  user,
  children,
  ...restProps
}) => (
  <Route
    {...restProps}
    render={({ location }) => {
      if (user?.isAuthorized) {
        return children;
      }
      if (!user?.isAuthorized) {
        return (
          <Redirect
            to={{
              pathname: SIGNIN,
              state: { from: location },
            }}
          />
        );
      }

      return null;
    }}
  />
);

export const ProtectedResultsRoute: React.FC<IRoutesHelper & RouteProps> = ({
  user,
  children,
  ...restProps
}) => (
  <Route
    {...restProps}
    render={({ location }) => {
      if (user?.isAuthorized) {
        if (user.didVote) {
          return children;
        }
        return (
          <Redirect
            to={{
              pathname: CONDIDATES,
              state: { from: location },
            }}
          />
        );
      }
      if (!user?.isAuthorized) {
        return (
          <Redirect
            to={{
              pathname: SIGNIN,
              state: { from: location },
            }}
          />
        );
      }

      return null;
    }}
  />
);
