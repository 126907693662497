import styled from "styled-components/macro";

export const Container = styled.footer`
  margin: 0 auto;
  width: 80%;
  text-align: center;
`;

export const CopyRights = styled.p`
  font-size: 13px;
  color: white;
  letter-spacing: 0.97px;
  margin-top: 3rem;
`;
