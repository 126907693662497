import votingURLs from "./voting.constants";

import { Services, CrudService } from "../../services/crud.services";

export const getCondidates = () => CrudService.Get(Services.VOTING, votingURLs.ELECTIONS_LIST_PATH);

export const voteCondidate = (voteInfos: FormData) =>
    CrudService.Post(Services.VOTING, votingURLs.VOTE_PATH, voteInfos);

export const displayResult = () => CrudService.Get(Services.VOTING, votingURLs.ELECTIONS_LIST_PATH);

export const userDidVote = (electionID: string) =>
    CrudService.Get(Services.VOTING, votingURLs.USER_DID_VOTE_PATH(electionID));
