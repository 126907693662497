import React from "react";
import { IFooterComposition } from "../../interfaces/IFooter";
import { Container, CopyRights } from "./styles/Footer";

const Footer: React.FC & IFooterComposition = ({ children, ...restProps }) => (
  <Container {...restProps}>{children}</Container>
);

Footer.CopyRights = function FooterCopyRights({ children, ...restProps }) {
  return <CopyRights {...restProps}>{children}</CopyRights>;
};

export default Footer;
