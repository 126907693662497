import styled from "styled-components/macro";

export const Container = styled.div`
    padding: 24px 0;
    margin: 0 auto;
    width: 80%;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    border-bottom: 1px solid #ffffff88;

    &:last-of-type {
        border: none;
    }

    @media (min-width: 800px) {
        padding: 3rem 0;
        width: min(1000px, 80%);
        margin: 0 auto;
    }

    &:last-of-type {
        border: none;
    }
`;

export const Infos = styled.div`
    color: white;
    text-align: center;

    @media (min-width: 800px) {
        flex: 1;
    }
`;

export const Picture = styled.img`
    width: 200px;
    height: 200px;
    background-color: gray;
    border: 4px solid #44b7ea;
    border-radius: 50%;
    object-fit: cover;
`;
export const ProgressBar = styled.div`
    display: flex;
    align-items: center;
    gap: 10px;

    @media (max-width: 800px) {
        gap: 0;
        flex-direction: column-reverse;
    }
`;

export const Name = styled.p`
    font-weight: 600;
    font-size: 1.5rem;
    margin: 1rem 0 0 0;
`;

export const NameResult = styled.p`
    font-weight: 600;
    font-size: 1.5rem;
    margin: 0 0 0 0;

    @media (max-width: 800px) {
        margin: 1rem 0 0 0;
    }
`;

export const Role = styled.p`
    margin: 0.5rem 0 0.75rem;

    @media (min-width: 800px) {
        margin: 0;
    }
`;

export const NBVotes = styled(Role)``;

export const Bar = styled.progress`
    ::-webkit-progress-bar {
        border-radius: 99px;
        background-color: white;
    }
    &[value] {
        -webkit-appearance: none;
        appearance: none;
        width: 250px;
        height: 8px;

        ::-webkit-progress-value {
            border-radius: 9999px;
            background-color: #44b7ea;
        }
    }
`;

export const Percent = styled.p`
    color: white;
    width: 90px;
    -webkit-text-stroke: 0.5px white;
`;
