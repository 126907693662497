import { useEffect, useState } from "react";
import { auth } from "../lib/firebase.prod";
// import { checkEmail } from "../modules/Voter/voter.crud";

const useAuthListner = () => {
  const [user, setUser] = useState(
    JSON.stringify(localStorage.getItem("authUser"))
  );

  // useEffect(() => {
  //   // const listner = auth.onAuthStateChanged((authUser) => {
  //   //   if (authUser) {
  //   //     const { email } = authUser;
  //   //     /* checkEmail({ email }).then(({ data }) => {
  //   //       if (data) {
  //   //         localStorage.setItem("authUser", JSON.stringify(authUser));
  //   //         setUser(JSON.stringify(authUser));
  //   //       } else {
  //   //         localStorage.removeItem("authUser");
  //   //         setUser("");
  //   //       }
  //   //     }); */
  //   //   } else {
  //   //     setUser("");
  //   //   }
  //   // });

  //   return listner;
  // }, []);

  return [user];
};

export default useAuthListner;
