import axios, { AxiosRequestConfig } from "axios";
import { CrudService, Services } from "../../services/crud.services";
import usersURLs from "./users.constants";
/* import usersURLs from "./users.constants";
import { Services, CrudService } from "../../services/crud.services"; */

export const login = (credential: FormData) =>
    CrudService.Post(Services.USERS, usersURLs.LOGIN_PATH, credential);

/*   import voterURLs from "./voter.constants";
  import { Services, CrudService } from "../../services/crud.services";
  
  export const checkEmail = (email: { email: string | null }) =>
    CrudService.Get(Services.VOTER, voterURLs.VALID_VOTER_PATH, email);
  
  export const displayResult = () =>
    CrudService.Get(Services.VOTER, voterURLs.DISPLAY_RESULT_PATH);
  
  export const getResults = () =>
    CrudService.Get(Services.VOTER, voterURLs.VOTES_RESULTS_PATH);
  
  export const getCondidates = () =>
    CrudService.Get(Services.VOTER, voterURLs.CONDIDATES_PATH);
  
  export const checkVoted = (email: { email: string | null }) =>
    CrudService.Get(Services.VOTER, voterURLs.CHECK_VOTED_PATH, email);
  
  /* 
  export const deleteCodePromo = (idCodePromo) => {
    return CrudService.Post(
      Services.FACTURATION_ENDPOINT,
      promotionUrls.DELETE_PROMOTION_PATCH + idCodePromo
    );
  };
  export const addCodePromo = (codePromo) => {
    return CrudService.Post(
      Services.FACTURATION_ENDPOINT,
      promotionUrls.ADD_PROMOTION_PATH,
      codePromo
    );
  };
   */
