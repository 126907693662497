/* eslint-disable no-param-reassign */
import axios, { AxiosRequestConfig } from "axios";

const URL_ROOT = "https://cse-vote-api-2022.azurewebsites.net/api";

export const Services = {
    USERS: `${URL_ROOT}/token`,
    VOTING: `${URL_ROOT}/`,
    ADMIN: `${URL_ROOT}/stats`,
};

function Get(
    service: string,
    url: string,
    params = axios.defaults.params,
    headers = axios.defaults.headers
) {
    return axios.get(`${service}${url}`, {
        headers,
        params,
    });
}
function GetDataForm(service: string, url: string, data: any, headers = axios.defaults.headers) {
    return axios.get(
        `${service}${url}`,
        data,
        // @ts-ignore
        {
            headers,
        }
    );
}

function Put(service: string, url: string, data: any, headers = axios.defaults.headers) {
    return axios.put(`${service}${url}`, data, {
        headers,
    });
}

function Post(service: string, url: string, data: any, headers = axios.defaults.headers) {
    return axios.post(`${service}${url}`, data, {
        headers,
    });
}

function Delete(service: string, url: string, data: any, headers = axios.defaults.headers) {
    const config: AxiosRequestConfig = {
        method: "delete",
        url: `${service}${url}`,
        headers,
        data,
    };
    return axios(config);
}

function setAuthHeader(authToken: string) {
    axios.interceptors.request.use(
        (config) => {
            if (authToken) {
                config.headers.Authorization = authToken;
            }
            return config;
        },
        (err) => Promise.reject(err)
    );
}

export const CrudService = {
    Put,
    Get,
    GetDataForm,
    Post,
    Delete,
    setAuthHeader,
};
