import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Switch, Route, Redirect } from "react-router-dom";
import useAuthListner from "./hooks/useAuthListner";
import { Home } from "./pages";
import * as ROUTES from "./constants/routes";
import Condidates from "./pages/Condidates";
import { ProtectedCondidatesRoute, ProtectedResultsRoute } from "./helpers/routes";
import Results from "./pages/Results";
import SignIn from "./pages/Signin";
import Page404 from "./pages/404";
import { CrudService } from "./services/crud.services";
import ElectionsClosed from "./pages/ElectionsClosed";

export interface IUser {
    user: {
        username: string;
        password: string;
    };
    token: string;
    isAuthorized: boolean;
    didVote: boolean | undefined;
}

const App: React.FC = () => {
    const [user] = useAuthListner();
    const [globalUser, setGlobalUser] = useState<IUser>({
        user: {
            username: "",
            password: "",
        },
        token: "",
        isAuthorized: false,
        didVote: undefined,
    });

    if (globalUser.isAuthorized) {
        CrudService.setAuthHeader(`Bearer ${globalUser.token}`);
    }

    return (
        <Router>
            <Switch>
                {/* Vote Route */}
                <ProtectedCondidatesRoute user={globalUser} path={ROUTES.CONDIDATES} exact>
                    {globalUser.didVote ? (
                        <Redirect to={{ pathname: ROUTES.RESULTS }} />
                    ) : (
                        <Condidates globalUser={globalUser} setGlobalUser={setGlobalUser} />
                    )}
                </ProtectedCondidatesRoute>
                {/* Results Route */}
                <ProtectedResultsRoute user={globalUser} exact path={ROUTES.RESULTS}>
                    <Results />
                </ProtectedResultsRoute>
                {/* SIGNIN Route */}
                <Route exact path={ROUTES.SIGNIN}>
                    <SignIn globalUser={globalUser} setGlobalUser={setGlobalUser} />
                </Route>
                {/* HOME Route */}
                <Route exact path={ROUTES.HOME}>
                    <Home setGlobalUser={setGlobalUser} />
                </Route>
                <Route exact path={ROUTES.CLOSED}>
                    <ElectionsClosed />
                </Route>
                {/* 404 Route */}
                <Route>
                    <Page404 />
                </Route>
            </Switch>
        </Router>
    );
};

export default App;
