/* eslint-disable camelcase */
// eslint-disable-next-line no-shadow
const votingURLs = {
    ELECTIONS_LIST_PATH: "elections/",
    ELECTION_PATH: "election/",
    USER_DID_VOTE_PATH: (electionId: string) => `election/${electionId}/userdidvote/`,
    VOTE_PATH: "vote/",
};

export default votingURLs;
