/* eslint-disable jsx-a11y/media-has-caption */
import React from "react";
import { IHeroComposition } from "../../interfaces/IHero";
// @ts-ignore
import src from "../../animations/animation_hero.mp4";
import { Button, Container, Desc, Video } from "./styles/Hero";

const Hero: React.FC & IHeroComposition = ({ children, ...restProps }) => (
    <Container {...restProps}>{children}</Container>
);

Hero.Video = function HeroVideo({ children, ...restProps }) {
    return (
        <Video {...restProps}>
            <video id="netflix-player" autoPlay loop muted playsInline aria-hidden="true">
                <source src={src} type="video/mp4" />
            </video>
        </Video>
    );
};

Hero.Button = function HeroButton({ children, ...restProps }) {
    return <Button {...restProps}>{children}</Button>;
};

Hero.Desc = function HeroDesc({ children, ...restProps }) {
    return <Desc {...restProps}>{children}</Desc>;
};

export default Hero;
