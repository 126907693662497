/* eslint-disable camelcase */
/* eslint-disable consistent-return */

import { useHistory } from "react-router-dom";
import React, { useEffect, useState } from "react";

import * as ROUTES from "../constants/routes";
import { getResults } from "../modules/Admin/admin.crud";
import { displayResult } from "../modules/Voting/voting.crud";

import { roundDecimal } from "../helpers/utils";

import logoutIcon from "../images/logout.svg";
import websiteIcon from "../images/website.svg";
import facebookIcon from "../images/facebook.svg";
import instagramIcon from "../images/instagram.svg";

import { Hero } from "../components";
import Card from "../components/card";
import Footer from "../components/footer";
import Header from "../components/header";
import { Loader } from "../components/popup/styles/PopUp";

interface Stats {
    positiveScore: number;
    negativeScore: number;
    totalScore: number;
    totalVoters: number;
}

const Results = () => {
    const history = useHistory();
    const [stats, setStats] = useState<Stats>();
    const [showResult, setShowResult] = useState<boolean>();

    useEffect(() => {
        displayResult().then(({ data }) => {
            if (!data[0].is_results_public) return setShowResult(false);

            getResults(data[0].election_id)
                .then((res) => {
                    const { stats_condidats, total_voters } = res.data as any;

                    const { score: positiveScore } = stats_condidats.find(
                        ({ condidat }) => condidat === "YES"
                    ) as any;
                    const { score: negativeScore } = stats_condidats.find(
                        ({ condidat }) => condidat === "NO"
                    ) as any;

                    setStats({
                        negativeScore,
                        positiveScore,
                        totalScore: negativeScore + positiveScore,
                        totalVoters: total_voters,
                    });
                    setShowResult(true);
                })
                .catch((err) => console.log(err));
        });
    }, []);

    const disconnect = () => {
        history.push(ROUTES.HOME);
    };

    return (
        <>
            <Header>
                <Header.Logo />
                <Header.Group>
                    <Header.Pane>
                        <Header.Media
                            link="https://www.instagram.com/cse.club/"
                            src={instagramIcon}
                        />
                        <Header.Media
                            link="https://www.facebook.com/club.scientifique.esi/"
                            src={facebookIcon}
                        />
                        <Header.Media link="https://cse.club/" src={websiteIcon} />
                    </Header.Pane>
                    <Header.Pane onClick={disconnect}>
                        <Header.LogOut src={logoutIcon}>Disconnect</Header.LogOut>
                    </Header.Pane>
                </Header.Group>
            </Header>
            <Header.Title>Vote Results</Header.Title>

            {showResult === undefined && <Loader style={{ margin: "200px 0" }} />}

            {showResult === true && (
                <Card>
                    <Card.Picture src="https://ca.slack-edge.com/TDUK7EAJC-U01J5SW7HMM-aa245dc591fd-512" />
                    <Card.Infos>
                        <Card.Name>Wissam Aissaoui</Card.Name>
                        <Card.NBVotes>{stats.totalVoters} Votes</Card.NBVotes>
                        <Card.NBVotes>Score : {roundDecimal(stats.positiveScore)}</Card.NBVotes>
                    </Card.Infos>
                    <Card.ProgressBar max={stats.totalScore} value={stats.positiveScore}>
                        {roundDecimal((stats.positiveScore / stats.totalScore) * 100)}% YES
                    </Card.ProgressBar>
                    <Card.ProgressBar max={stats.totalScore} value={stats.negativeScore}>
                        {roundDecimal((stats.negativeScore / stats.totalScore) * 100)}% NO
                    </Card.ProgressBar>
                </Card>
            )}

            {showResult === false && (
                <Hero>
                    <Hero.Video />
                    <Hero.Desc>
                        You voted, you cannot see the results at this time unless the voting period
                        has ended. We will update you on slack. Stay tuned ...
                    </Hero.Desc>
                </Hero>
            )}

            <Footer>
                <Footer.CopyRights>
                    Copyright © 2022 Club Scientifique de l'ESI. All Rights Reserved. | Privacy
                    Policy
                </Footer.CopyRights>
            </Footer>
        </>
    );
};

export default Results;
