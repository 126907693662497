import React, { createContext, useContext, useState } from "react";
import ReactDOM from "react-dom";
import Lottie from "react-lottie";
import { Redirect, useHistory } from "react-router-dom";

import { IPopUpComposition } from "../../interfaces/IPopUp";
import * as done from "../../animations/57767-done.json";
import * as failed from "../../animations/34313-failure-error-icon.json";
import * as error from "../../animations/38213-error.json";
import * as ROUTES from "../../constants/routes";

import {
  Account,
  Back,
  Button,
  Choice,
  Condidature,
  Confirmation,
  Container,
  Content,
  Continue,
  Detail,
  Email,
  Finalize,
  Icon,
  Inner,
  Link,
  Loader,
  Name,
  NB,
  NBText,
  Overlay,
  Picture,
  Status,
  Title,
  Vote,
} from "./styles/PopUp";
import { auth } from "../../lib/firebase.prod";

interface IPopUpContext {
  showPopUp: boolean;
  setShowPopUp: React.Dispatch<React.SetStateAction<boolean>>;
}

export const PopUpContext = createContext<IPopUpContext>({
  showPopUp: false,
  setShowPopUp: () => {},
});

const PopUp: React.FC & IPopUpComposition = ({ children, ...restProps }) => {
  const [showPopUp, setShowPopUp] = useState(false);

  return (
    <PopUpContext.Provider value={{ showPopUp, setShowPopUp }}>
      <Container {...restProps}>{children}</Container>
    </PopUpContext.Provider>
  );
};

PopUp.Content = function PopUpContent({ children, ...restProps }) {
  const { showPopUp, setShowPopUp } = useContext(PopUpContext);

  return showPopUp
    ? ReactDOM.createPortal(
        <Overlay>
          <Inner>
            <Content {...restProps}>{children}</Content>
          </Inner>
        </Overlay>,
        document.body
      )
    : null;
};

PopUp.Button = function PopUpButton({ cta, children, ...restProps }) {
  const { setShowPopUp } = useContext(PopUpContext);

  const handleClick = () => {
    setShowPopUp((prev) => !prev);
    cta();
  };

  return (
    <Button onClick={handleClick} {...restProps}>
      {children}
    </Button>
  );
};
PopUp.Link = function PopUpLink({ children, ...restProps }) {
  const { setShowPopUp } = useContext(PopUpContext);
  return (
    <Link onClick={() => setShowPopUp((prev) => !prev)} {...restProps}>
      {children}
    </Link>
  );
};

PopUp.Loader = function PopUpLoader({ children, ...restProps }) {
  return <Loader {...restProps} />;
};

PopUp.Detail = function PopUpDetail({ children, ...restProps }) {
  return <Detail {...restProps}>{children}</Detail>;
};

PopUp.Title = function PopUpTitle({ children, ...restProps }) {
  return <Title {...restProps}>{children}</Title>;
};

PopUp.Picture = function PopUpPicture({ src, alt, children, ...restProps }) {
  return (
    <Picture src={src || undefined} alt={alt} {...restProps}>
      {children}
    </Picture>
  );
};

PopUp.Choice = function PopUpChoice({ children, ...restProps }) {
  return <Choice {...restProps}>{children}</Choice>;
};

PopUp.Name = function PopUpName({ children, ...restProps }) {
  return <Name {...restProps}>{children}</Name>;
};

PopUp.Confirmation = function PopUpConfirmation({ children, ...restProps }) {
  return <Confirmation {...restProps}>{children}</Confirmation>;
};

PopUp.Email = function PopUpEmail({ children, ...restProps }) {
  return <Email {...restProps}>{children}</Email>;
};

PopUp.Status = function PopUpStatus({ icon, children, ...restProps }) {
  const doneOptions = {
    loop: false,
    autoplay: true,
    // @ts-ignore
    animationData: done.default,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
  const failedOptions = {
    loop: false,
    autoplay: true,
    // @ts-ignore
    animationData: failed.default,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  return (
    <Status {...restProps}>
      <Icon>
        {icon ? (
          <Lottie options={doneOptions} height={40} width={40} />
        ) : (
          <Lottie
            options={failedOptions}
            height={25}
            width={25}
            style={{ marginRight: 8 }}
          />
        )}
      </Icon>
      <Account>{children}</Account>
    </Status>
  );
};

PopUp.NB = function PopUpNB({ children, ...restProps }) {
  const nbOptions = {
    loop: true,
    autoplay: true,
    // @ts-ignore
    animationData: error.default,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
  return (
    <NB {...restProps}>
      <Icon>
        <Lottie
          options={nbOptions}
          height={40}
          width={40}
          style={{ marginRight: 8 }}
        />
      </Icon>
      <NBText>
        Once you have clicked continue, <u>you cannot</u> change your vote.
      </NBText>
    </NB>
  );
};

PopUp.Continue = function PopUpContinue({ eligible, children, ...restProps }) {
  const history = useHistory();

  const handleClick = () => {
    if (eligible) {
      history.push(ROUTES.CONDIDATES);
    } else {
      auth.signOut();
    }
  };
  return (
    <Continue onClick={handleClick} {...restProps}>
      {children}
    </Continue>
  );
};

PopUp.Finalize = function PopUpFinalize({ cta, children, ...restProps }) {
  const { setShowPopUp } = useContext(PopUpContext);

  const handleClick = () => {
    cta();
  };
  return (
    <Finalize onClick={handleClick} {...restProps}>
      {children}
    </Finalize>
  );
};

PopUp.Vote = function PopUpContinue({ children, ...restProps }) {
  const { setShowPopUp } = useContext(PopUpContext);
  return (
    <Vote onClick={() => setShowPopUp((prev) => !prev)} {...restProps}>
      {children}
    </Vote>
  );
};

PopUp.Back = function PopUpBack({ children, ...restProps }) {
  const { setShowPopUp } = useContext(PopUpContext);

  return (
    <Back onClick={() => setShowPopUp((prev) => !prev)} {...restProps}>
      {children}
    </Back>
  );
};

PopUp.Condidature = function PopUpCondidature({
  link,
  children,
  ...restProps
}) {
  return (
    <Condidature {...restProps}>
      <a href={link} target="_blank" rel="noreferrer">
        {children}
      </a>
    </Condidature>
  );
};

export default PopUp;
