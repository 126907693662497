import styled from "styled-components/macro";

export const Container = styled.div`
    margin: 0 auto;
    width: 80%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
`;

export const Video = styled.div`
    display: flex;
    justify-content: center;
    z-index: 0;

    video {
        width: 320px;
        z-index: 0;
    }

    @media (max-width: 800px) {
        video {
            width: 280px;
        }
    }
`;

export const Desc = styled.p`
    color: white;
    width: min(660px, 100%);
    font-size: 1.25rem;
`;

export const Button = styled.button`
    border: none;
    background: none;
    outline: none;
    background-color: #44b7ea;
    padding: 0.75rem 3rem;
    font-weight: bold;
    color: white;
    border-radius: 8px;
    cursor: pointer;
    border: 2px solid #44b7ea;

    &:hover {
        background-color: #00b1ff;
    }
`;
