import React from "react";
import { useHistory } from "react-router-dom";
import { HOME } from "../../constants/routes";
import { IHeaderComposition } from "../../interfaces/IHeader";
import {
    Container,
    Group,
    Icon,
    Logo,
    LogOut,
    Media,
    MediaHero,
    Pane,
    Title,
} from "./styles/Header";

const Header: React.FC & IHeaderComposition = ({ children, ...restProps }) => (
    <Container {...restProps}>{children}</Container>
);

Header.Title = function HeaderLogo({ children, ...restProps }) {
    return <Title {...restProps}>{children}</Title>;
};

Header.Logo = function HeaderLogo({ ...restProps }) {
    const history = useHistory();

    return <Logo onClick={() => history.push(HOME)} {...restProps} />;
};

Header.Group = function HeaderGroup({ children, ...restProps }) {
    return <Group {...restProps}>{children}</Group>;
};

Header.Pane = function HeaderPane({ children, ...restProps }) {
    return <Pane {...restProps}>{children}</Pane>;
};

Header.Media = function HeaderMedi({ link, src, ...restProps }) {
    return (
        <Media href={link}>
            <Icon src={src} {...restProps} />
        </Media>
    );
};

Header.MediaHero = function HeaderMediaHero({ link, src, ...restProps }) {
    return (
        <MediaHero href={link}>
            <Icon src={src} {...restProps} />
        </MediaHero>
    );
};

Header.LogOut = function HeaderLogOut({ src, children, ...restProps }) {
    return (
        <LogOut>
            {children}
            <Icon src={src} {...restProps} style={{ width: 21, marginLeft: 16 }} />
        </LogOut>
    );
};

export default Header;
