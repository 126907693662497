import React, { useEffect, useState } from "react";
import { useHistory, useRouteMatch } from "react-router-dom";
import { auth } from "../lib/firebase.prod";
import * as ROUTES from "../constants/routes";
import Header from "../components/header";
import instagramIcon from "../images/instagram.svg";
import facebookIcon from "../images/facebook.svg";
import websiteIcon from "../images/website.svg";
import logoutIcon from "../images/logout.svg";
import Card from "../components/card";
import { PopUp } from "../components";
import Footer from "../components/footer";
import { getCondidates, userDidVote, voteCondidate } from "../modules/Voting/voting.crud";
import { IUser } from "../App";
import { Loader } from "../components/popup/styles/PopUp";

interface ICondidate {
    id: string;
    name: string;
    department: string;
    condidature: string;
    image: string;
}

interface IProps {
    globalUser: IUser;
    setGlobalUser: React.Dispatch<React.SetStateAction<IUser>>;
}

const Condidates: React.FC<IProps> = ({ globalUser, setGlobalUser }) => {
    const history = useHistory();
    const [condidate, setCondidate] = useState<ICondidate[]>([]);
    const [election, setElection] = useState("");

    const [showCondidates, setShowCondidates] = useState(false);
    const [loadingVote, setLoadingVote] = useState(false);

    useEffect(() => {
        const unsubscribe = () => {
            getCondidates().then(({ data }) => {
                if (data[0].is_results_public) history.push(ROUTES.RESULTS);
                if (!data[0].is_election_open) history.push(ROUTES.CLOSED);

                setElection(data[0].election_id);
                data[0].candidats.map((item: any) =>
                    setCondidate((prev) => [
                        ...prev,
                        {
                            id: item.candidat_id,
                            name: item.candidat_full_name,
                            department: item.deparetement,
                            condidature: item.cadidature,
                            image: item.image,
                        },
                    ])
                );
            });
        };

        return unsubscribe();
    }, []);

    useEffect(() => {
        const unsubscribe = () => {
            getCondidates().then(({ data }) => {
                userDidVote(data[0].election_id)
                    .then((res) => {
                        setGlobalUser({ ...globalUser, didVote: res.data });
                        const timer = setTimeout(() => {
                            setShowCondidates(true);
                        }, 1100);
                        return () => clearTimeout(timer);
                    })
                    .catch((err) => {
                        const timer = setTimeout(() => {
                            setShowCondidates(true);
                        }, 1100);
                        console.log(err);
                        return () => clearTimeout(timer);
                    });
            });
        };
        return unsubscribe();
    }, []);

    const vote = (condidateID: string) => {
        setLoadingVote(true);
        const dataForm = new FormData();

        dataForm.append("candidate_id", condidateID);
        dataForm.append("election_id", election);

        voteCondidate(dataForm).then(({ data }) => {
            setGlobalUser({ ...globalUser, didVote: true });
            const timer = setTimeout(() => {
                setLoadingVote(false);
                history.push(ROUTES.RESULTS);
            }, 2500);
            return () => clearTimeout(timer);
        });
    };

    const yesToWissam = () => {
        const { id } = condidate.find(({ name }) => name === "YES");

        vote(id);
    };

    const noToWissam = () => {
        const { id } = condidate.find(({ name }) => name === "NO");

        vote(id);
    };

    const disconnect = () => {
        history.push(ROUTES.HOME);
    };

    return (
        <>
            <Header>
                <Header.Logo />
                <Header.Group>
                    <Header.Pane>
                        <Header.Media
                            link="https://www.instagram.com/cse.club/"
                            src={instagramIcon}
                        />
                        <Header.Media
                            link="https://www.facebook.com/club.scientifique.esi/"
                            src={facebookIcon}
                        />
                        <Header.Media link="https://cse.club/" src={websiteIcon} />
                    </Header.Pane>
                    <Header.Pane onClick={disconnect}>
                        <Header.LogOut src={logoutIcon}>Disconnect</Header.LogOut>
                    </Header.Pane>
                </Header.Group>
            </Header>
            {showCondidates ? (
                <>
                    <Header.Title>Do You Accept Wissam as Our Next President</Header.Title>

                    <Card>
                        <Card.Picture src="https://ca.slack-edge.com/TDUK7EAJC-U01J5SW7HMM-aa245dc591fd-512" />
                        <Card.Infos>
                            <Card.Name>Wissam Aissaoui</Card.Name>
                            <Card.Role>General secretary</Card.Role>
                            <PopUp>
                                <PopUp.Link>More details</PopUp.Link>
                                <PopUp.Content>
                                    <PopUp.Detail>
                                        <PopUp.Title>Candidate Details</PopUp.Title>
                                        <PopUp.Picture
                                            src="https://ca.slack-edge.com/TDUK7EAJC-U01J5SW7HMM-aa245dc591fd-512"
                                            alt="condidate-photo"
                                        />
                                        <PopUp.Name>Wissam Aissaoui</PopUp.Name>
                                        <PopUp.Email>kw_aissaoui@esi.dz</PopUp.Email>
                                        <PopUp.Condidature link="https://drive.google.com/file/d/1jZH0SSMjqBt9_QPGSFNQiNxTu4sbY6R4/view">
                                            Candidature
                                        </PopUp.Condidature>
                                        <PopUp.Back>Back</PopUp.Back>
                                    </PopUp.Detail>
                                </PopUp.Content>
                            </PopUp>
                        </Card.Infos>
                        <PopUp>
                            <PopUp.Vote>Yes</PopUp.Vote>
                            <PopUp.Content>
                                {loadingVote ? (
                                    <PopUp.Loader />
                                ) : (
                                    <PopUp.Detail>
                                        <PopUp.Title>Vote Confirmation</PopUp.Title>
                                        <PopUp.Picture
                                            src="https://ca.slack-edge.com/TDUK7EAJC-U01J5SW7HMM-aa245dc591fd-512"
                                            alt="condidate-photo"
                                        />
                                        <PopUp.Confirmation>
                                            You have chosen to vote on{" "}
                                            <PopUp.Choice>Wissam Aissaoui</PopUp.Choice>, do you
                                            want to continue?
                                        </PopUp.Confirmation>
                                        <PopUp.Finalize cta={yesToWissam}>Continue</PopUp.Finalize>
                                        <PopUp.Back>Back</PopUp.Back>
                                        <PopUp.NB />
                                    </PopUp.Detail>
                                )}
                            </PopUp.Content>
                        </PopUp>
                        <PopUp>
                            <PopUp.Back>No</PopUp.Back>
                            <PopUp.Content>
                                {loadingVote ? (
                                    <PopUp.Loader />
                                ) : (
                                    <PopUp.Detail>
                                        <PopUp.Title>Vote Confirmation</PopUp.Title>
                                        <PopUp.Picture
                                            src="https://ca.slack-edge.com/TDUK7EAJC-U01J5SW7HMM-aa245dc591fd-512"
                                            alt="condidate-photo"
                                        />
                                        <PopUp.Confirmation>
                                            You have chosen <PopUp.Choice>not</PopUp.Choice> to vote
                                            on <PopUp.Choice>Wissam Aissaoui</PopUp.Choice>, do you
                                            want to continue?
                                        </PopUp.Confirmation>
                                        <PopUp.Finalize cta={noToWissam}>Continue</PopUp.Finalize>
                                        <PopUp.Back>Back</PopUp.Back>
                                        <PopUp.NB />
                                    </PopUp.Detail>
                                )}
                            </PopUp.Content>
                        </PopUp>
                    </Card>

                    <Footer>
                        <Footer.CopyRights>
                            Copyright © 2022 Club Scientifique de l'ESI. All Rights Reserved. |
                            Privacy Policy
                        </Footer.CopyRights>
                    </Footer>
                </>
            ) : (
                <Loader style={{ marginTop: 120 }} />
            )}
        </>
    );
};

export default Condidates;
