/* eslint-disable no-nested-ternary */
import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import Header from "../components/header";
import instagramIcon from "../images/instagram.svg";
import facebookIcon from "../images/facebook.svg";
import websiteIcon from "../images/website.svg";
import { Hero } from "../components";
import * as ROUTES from "../constants/routes";
import Footer from "../components/footer";
import { IUser } from "../App";

const ElectionsClosed: React.FC = () => {
    const history = useHistory();

    return (
        <>
            <Header>
                <Header.Logo />
                <Header.Group>
                    <Header.Pane>
                        <Header.MediaHero
                            link="https://www.instagram.com/cse.club/"
                            src={instagramIcon}
                        />
                        <Header.MediaHero
                            link="https://www.facebook.com/club.scientifique.esi/"
                            src={facebookIcon}
                        />
                        <Header.MediaHero link="https://cse.club/" src={websiteIcon} />
                    </Header.Pane>
                </Header.Group>
            </Header>
            <Header.Title>
                CSE Presidential
                <br />
                2022 - 2023
            </Header.Title>
            <Hero>
                <Hero.Video />

                <Header.Title>Elections Are Closed!</Header.Title>
            </Hero>

            <Footer>
                <Footer.CopyRights>
                    Copyright © 2022 Club Scientifique de l'ESI. All Rights Reserved. | Privacy
                    Policy
                </Footer.CopyRights>
            </Footer>
        </>
    );
};

export default ElectionsClosed;
