import styled from "styled-components/macro";

export const Container = styled.div`
    width: 100%;
`;

export const Content = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    color: white;
    padding: 3rem;
`;

export const Overlay = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
`;

export const Inner = styled.div`
    position: relative;
    width: max(290px, 80%);
    max-width: 450px;
    min-height: 410px;
    margin: auto;
    background-color: #383d59;
    box-shadow: 0 2px 12px hsl(0deg 0% 0% / 71%);

    ${Content} {
        height: 100%;
        width: 100%;
    }

    animation: fadeIn 0.5s ease;

    @keyframes fadeIn {
        from {
            opacity: 0;
        }

        to {
            opacity: 1;
        }
    }
`;

export const Button = styled.button`
    border: none;
    background: none;
    outline: none;
    background-color: #44b7ea;
    padding: 0.75rem 3rem;
    font-weight: bold;
    color: white;
    border-radius: 8px;
    cursor: pointer;
    border: 2px solid #44b7ea;

    &:hover {
        background-color: #00b1ff;
    }
`;

export const Loader = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    &::before,
    &::after {
        content: "";
        width: 50px;
        height: 50px;
        position: absolute;
        border: solid 8px transparent;
        border-radius: 50%;
        -webkit-animation: wiggle 1.4s ease infinite;
        animation: wiggle 1.4s ease infinite;
    }

    &::before {
        border-top-color: #00baee;
        border-bottom-color: #ebd000;
    }

    &::after {
        border-left-color: white;
        border-right-color: white;
        -webkit-animation-delay: 0.7s;
        animation-delay: 0.7s;
    }

    @-webkit-keyframes wiggle {
        0% {
            transform: rotate(0deg) scale(1);
        }
        50% {
            transform: rotate(180deg) scale(0.5);
        }
        100% {
            transform: rotate(360deg) scale(1);
        }
    }

    @keyframes wiggle {
        0% {
            transform: rotate(0deg) scale(1);
        }
        50% {
            transform: rotate(180deg) scale(0.5);
        }
        100% {
            transform: rotate(360deg) scale(1);
        }
    }
`;

export const Detail = styled.div``;

export const Title = styled.h4`
    margin: 0;
`;

export const Picture = styled.img`
    width: 120px;
    height: 120px;
    border-radius: 50%;
    margin-top: 1.5rem;

    background-color: gray;
    object-fit: cover;
`;

export const Name = styled.h3`
    margin: 0;
    padding-top: 4px;
    font-size: 22px;
`;

export const Confirmation = styled.p`
    margin: 0;
    padding-top: 4px;
    font-size: 22px;
`;

export const Choice = styled.span`
    font-weight: 700;
`;

export const Email = styled.p`
    margin: 10px 0 0 0;
`;

export const Status = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    padding-right: 8px;
    margin-bottom: 30px;
`;

export const Icon = styled.div``;

export const NB = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
`;

export const NBText = styled.p`
    text-align: justify;
    font-size: 0.85rem;
`;

export const Account = styled.p`
    margin: 14px 0;
`;

export const Continue = styled(Button)`
    padding: 12px 70px;
    font-size: 20px;
`;

export const Finalize = styled(Continue)`
    margin-top: 2.5rem;
    font-size: 20px;
    padding: 12px 20px;
    width: 100%;
    max-width: 250px;
`;

export const Condidature = styled(Button)`
    font-size: 20px;
    margin-top: 2.5rem;
    padding: 12px 20px;
    width: 100%;
    max-width: 250px;

    a {
        text-decoration: none;
        color: white;
    }
`;

export const Vote = styled(Button)`
    font-size: 20px;
    padding: 12px 20px;
    width: 100%;
    max-width: 250px;
`;

export const Back = styled(Button)`
    padding: 12px 20px;
    font-size: 20px;
    width: 100%;
    max-width: 250px;
    font-weight: 400;
    margin-top: 12px;
    background-color: transparent;
    border: 2px solid #44b7ea;
    -webkit-text-stroke: 1px white;

    &:hover {
        background-color: transparent;
        color: #44b7ea;
        -webkit-text-stroke: 1px #44b7ea;
    }
`;

export const Link = styled.p`
    cursor: pointer;
    font-weight: 600;
    text-decoration: underline;
    color: #44b7ea;

    @media (min-width: 800px) {
        margin: 0.75rem 0;
    }
`;
